import { LoanLink } from 'pages/Loan'
import { type DBInvestment, InvestStatus } from 'pages/Payment'
import { Modal } from 'stories/components'

import { InvestTable } from './Invests'

export const SameInvestModal = ({
  data: _data,
  isAdmin = false,
  onClose,
}: {
  data: DBInvestment[]
  isAdmin?: boolean
  onClose: Function
}) => {
  const filters = {
    duration: '-',
    orderBy: 'id',
    status: [InvestStatus.Settled],
    orderDir: -1,
    pageNum: 0,
    holder: '',
  }

  const refetch = () => {}

  return (
    <Modal
      isOpen
      title={<LoanLink loan={_data[0].loan} simple />}
      titleOkay=""
      titleCancel="Close"
      onClose={() => onClose(false)}
    >
      <div className="lg:w-260 w-[calc(100vw-4rem)] -m-6 overflow-y-auto overflow-x-hidden py-2 md:px-4 px-2">
        <InvestTable filters={filters} invests={_data} refetch={refetch} isAdmin={isAdmin} isChild={true} />
      </div>
    </Modal>
  )
}
