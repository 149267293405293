import type { InputType } from 'config'
import {
  AutoComplete,
  Checkbox,
  GoogleAutoComplete,
  GoogleAutoComplete2,
  Input,
  Input2,
  InputFile,
  InputFileTable,
  InputWithButton,
  MultiSelect,
  RadioGroup,
  RangeSlider,
  RichTextEditor,
  Select,
  Select2,
  TextArea,
  Toggle,
  ToggleButton,
} from 'stories/components'
import { ButtonGroupSelector } from 'stories/components/ButtonGroupSelector/ButtonGroupSelector'
import { InputFormTable } from 'stories/components/FormTable/InputFormTable'

interface Prop {
  input: InputType
  Key: string
  onChange: Function
  showHistory?: Function
  onBlur?: Function | null
}

export const RenderInput = (props: Prop) => {
  const { input, Key, onChange, showHistory = () => {}, onBlur = null } = props
  let {
    fontSize,
    history,
    prefix,
    title,
    rows,
    error = '',
    disabled,
    value = '',
    readOnly = false,
    sort = false,
    tooltip = '',
    acceptFileTypes,
    required,
    additionalElements,
    placeholder = '',
    autoComplete = 'off',
  } = input
  required = required && !disabled
  if (!(input.inputType as any)) input.inputType = 'Text' as any
  if (disabled && ['Map', 'map'].indexOf(input.inputType) !== -1) input.inputType = 'Text'

  switch (input.inputType) {
    case 'Text0':
    case 'text0':
      return (
        <Input
          {...input}
          title={title}
          placeholder={placeholder}
          key={Key}
          type={input.type}
          error={error}
          value={value as string}
          disabled={disabled}
          required={required}
          history={history}
          isLegacy={input.isLegacy}
          className={input.className}
          inputClassName={input.inputClassName}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
          hasIcon={input.hasIcon}
          icon={input.icon}
          autoFocus={input.autoFocus}
          autoComplete={autoComplete}
        />
      )
    case 'Text':
    case 'text':
      return (
        <Input2
          title={title}
          placeholder={placeholder}
          prefix={prefix}
          key={Key}
          type={input.type}
          error={error}
          value={value as string}
          fontSize={fontSize}
          disabled={disabled}
          readOnly={readOnly}
          tooltip={tooltip}
          required={required}
          history={history}
          copyable={input.copyable}
          additionalElements={additionalElements}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          onBlurNull={onBlur === null}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Select0':
    case 'select0':
      return (
        <Select
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          className={input.className}
          sort={input.sort}
          value={input.value as string}
          hasDefaultOption={input.hasDefaultOption}
          defaultOptionText={input.defaultOptionText}
          required={required}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Select':
    case 'select':
      return (
        <Select2
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          sort={sort}
          tooltip={tooltip}
          value={input.value as string}
          hasDefaultOption={input.hasDefaultOption}
          defaultOptionText={input.defaultOptionText}
          required={required}
          history={history}
          copyable={input.copyable}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'MultiSelect':
    case 'multiselect':
      return (
        <MultiSelect
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          error={error}
          disabled={disabled}
          readOnly={readOnly}
          tooltip={tooltip}
          value={input.value as Record<string, boolean>}
          required={required}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
          sort={input.sort}
        />
      )
    case 'Check':
    case 'check':
      return (
        <ToggleButton
          className="pt-1"
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
          options={['Yes', 'No']}
        />
      )
    case 'CheckBox':
    case 'checkbox':
      return (
        <Checkbox
          className={`pt-1 ${input.className}`}
          fontClass={input.fontClass}
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Radio':
    case 'radio':
      return (
        <RadioGroup
          id={Key}
          title={title}
          options={input.options}
          key={Key}
          name={Key}
          error={error}
          disabled={disabled}
          value={input.value as string}
          required={required}
          history={history}
          className={input.className}
          innerClassName={input.innerClassName}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'TextArea':
    case 'textarea':
      return (
        <TextArea
          className="pt-1"
          title={title}
          key={Key}
          rows={rows}
          error={error}
          disabled={disabled}
          value={input.value as string}
          history={history}
          required={required}
          placeholder={input.placeholder}
          isLegacy={input.isLegacy}
          onBlur={() => onBlur && onBlur(Key)}
          onBlurNull={onBlur === null}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Map':
    case 'map':
      return (
        <GoogleAutoComplete
          title={title}
          key={Key}
          inputKey={Key}
          error={error}
          value={value as string}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          tooltip={tooltip}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          history={history}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Map0':
    case 'map0':
      return (
        <GoogleAutoComplete2
          title={title}
          key={Key}
          inputKey={Key}
          error={error}
          value={value as string}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          tooltip={tooltip}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          history={history}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'Toggle':
    case 'toggle':
      return (
        <Toggle
          className="pt-1"
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'ToggleButton':
    case 'togglebutton':
      return (
        <ToggleButton
          className={`pt-1 ${input.className}`}
          id={Key}
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value as boolean}
          history={history}
          onChange={(value) => onChange(Key, value)}
          onBlur={() => onBlur && onBlur(Key)}
          showHistory={() => showHistory(Key)}
          textLeft={input.textLeft}
          options={input.options}
        />
      )
    case 'ButtonGroup':
    case 'buttongroup':
      return (
        <ButtonGroupSelector
          className="pt-1"
          title={title}
          key={Key}
          disabled={disabled}
          error={error}
          value={input.value}
          onChange={(value) => onChange(Key, value)}
          options={input.options}
          tooltip={input.tooltip}
        />
      )
    case 'File':
    case 'file':
      return (
        <InputFile
          className="pt-1"
          title={title}
          key={Key}
          acceptFileTypes={acceptFileTypes}
          disabled={disabled}
          value={input.value}
          error={error}
          history={history}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
          multiple={input.multiple}
        />
      )
    case 'FileTable':
    case 'filetable':
      return (
        <InputFileTable
          className="pt-1"
          title={title}
          filePath={input.filePath}
          key={Key}
          acceptFileTypes={acceptFileTypes}
          disabled={disabled}
          value={input.value}
          options={input.options}
          sort={input.sort}
          error={error}
          history={history}
          moreControls={input.moreControls}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'AutoComplete':
    case 'autocomplete':
      return (
        <AutoComplete
          className="pt-1"
          title={title}
          key={Key}
          value={value}
          disabled={disabled}
          error={error}
          type={input.type}
          autoCompleteType={input.autoCompleteType}
          required={input.required}
          onBlur={() => onBlur && onBlur(Key)}
          history={history}
          onChange={(value) => onChange(Key, value)}
          showHistory={() => showHistory(Key)}
        />
      )
    case 'InputWithButton':
    case 'inputwithbutton':
      return (
        <InputWithButton
          className=""
          title={title}
          key={Key}
          value={value}
          disabled={disabled}
          error={error}
          type={input.type}
          required={input.required}
          options={input.options}
          modalTitle={input.modalTitle}
          onChange={(value: any) => onChange(Key, value)}
        />
      )
    case 'Table':
    case 'table':
      return (
        <InputFormTable
          inputs={input.inputs}
          data={input.value}
          onChange={(value: any) => onChange(Key, value)}
          error={error}
          permission={disabled ? 3 : 1}
        />
      )
    case 'MultiRange':
    case 'multirange':
      return (
        <RangeSlider
          title={input.title}
          className="w-full h-4"
          defaultValue={value}
          value={value}
          min={input.min || 0}
          max={input.max || 100}
          error={input.error}
          onChange={(value, index) => onChange(Key, value, index)}
          onAfterChange={(value, index) => onBlur && onBlur(Key, value, index)}
        />
      )

    case 'Section':
    case 'section':
      return (
        <div className={`border-b-2 border-shade-blue w-full`}>
          <p>{title}</p>
        </div>
      )

    case 'Group':
    case 'group':
      return (
        <div className={`w-full bg-gray-200 p-2`}>
          <p>{title}</p>
        </div>
      )

    case 'Ckeditor':
    case 'ckeditor':
      return <RichTextEditor title="Description" value={value} onChange={(value: any) => onChange(Key, value)} />

    case 'Custom':
    case 'custom':
      return input.render ? input.render(input.value, (value: any) => onChange(Key, value), input) : null
    case 'alert':
      return (
        <div className={`input md:col-span-${input.span} mb-4`}>
          <div
            className="bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded relative text-[15px]"
            role="alert"
          >
            <span dangerouslySetInnerHTML={{ __html: input.value }} />
          </div>
        </div>
      )
  }
  return null
}
