import { LinkIcon } from '@heroicons/react/24/outline'
import { Image } from 'components/Image'
import { LayoutLoading } from 'components/LayoutLoading'
import { Loading } from 'components/Loading'
import { TransactionColorMap, TransactionStatus } from 'components/TransactionStatus'
import { PenaltyPercent } from 'config'
import { Tooltip } from 'flowbite-react'
import moment from 'moment-timezone'
import { estimatedRemainingProfit } from 'pages/Dashboard/logic'
import { EnvelopeColorMap, ProfitStatus } from 'pages/Invest'
import { remainingLoanTerm } from 'pages/Marketplace/InvestCard'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getEnvelopeUrl, signSellBackInvestment } from 'services/apis'
import { Button, Modal, PlainTable, Select2 } from 'stories/components'
import {
  confirm,
  dayProfit,
  formatDate,
  formatDateLong,
  formatTimeLong,
  monthlyProfit,
  normalizeNumber,
  thisTime,
  toS3Link,
} from 'utils'

import {
  type DBInvestment,
  DbProfit,
  type InvestEvent,
  InvestStatus,
  MergedInvestment,
  ProfitColorMap,
} from '../constants'

export const InvestDetails = ({
  data: _data,
  selectedInvestment: _invest = null,
  isMine = true,
  isAdmin = false,
  onClose,
  isChild = true,
}: {
  data: DBInvestment
  selectedInvestment?: MergedInvestment | null
  isMine?: boolean
  isAdmin?: boolean
  onClose: Function
  isChild?: boolean
}) => {
  const [selectedChild, setSelectedChild] = useState<DBInvestment>(_data)
  const [selectedChildText, setSelectedChildText] = useState<string>('#' + _data.id)
  const [action, setAction] = useState('')

  const onLinkRetradedDoc = () => {
    if (action) return
    setAction('retradedLink')

    getEnvelopeUrl(selectedChild!.loan.id, selectedChild!.retradeEnvelopeId!)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setAction(''))
  }

  const renderData = useMemo(() => {
    const invest = selectedChild!
    const { loan } = invest

    const strAmount = normalizeNumber(invest!.amount)

    const result: Record<string, any> = {
      'Loan Amount': `$ ${normalizeNumber(loan.loanAmount)}`,
      APY: `${normalizeNumber(loan.ytm, 2)} %`,
      'Estimated Remaining Interest': isChild ? `$ ${normalizeNumber(estimatedRemainingProfit(invest!), 2)}` : '',
      'Term Remaining': remainingLoanTerm(invest!.loan.maturityDate),
      'Investment Amount': `$ ${strAmount}`,
      // Method: data.method,
      Status: <TransactionStatus status={invest!.status} />,
      'Sign Request Date': formatDateLong(invest!.createdAt),
      'Document Status': (
        <div className="flex gap-2 items-center justify-end px-4 lg:px-0 lg:justify-start capitalize">
          <span className={`w-2 h-2 rounded-full ${EnvelopeColorMap[invest!.envelopeStatus]}`} />
          {invest!.envelopeStatus}
        </div>
      ),
    }

    if (invest!.status != InvestStatus.Settled) delete result['Estimated Remaining Interest']
    if (invest!.startDate) result['Invested Date'] = formatDateLong(invest!.startDate)
    if (invest!.lastProfitDate) result['Last Distribution Date'] = formatDateLong(invest!.lastProfitDate)
    result['Monthly Profit'] = `$ ${normalizeNumber(monthlyProfit(invest!.amount, invest!.ytm), 2)}`

    if (invest!.profit)
      result['Distributions'] = <p className="text-base font-bold text-green-500">+ $ {invest!.profit}</p>
    if (invest!.penalty && Number(invest!.penalty))
      result['Transaction Fee'] = (
        <p className="text-base font-bold text-red-500">- ${normalizeNumber(invest!.penalty)}</p>
      )

    return result
  }, [action, selectedChild, selectedChildText])

  const profits = useMemo(() => {
    if (!selectedChild!.profits) return null
    else {
      if (selectedChild !== null) {
        const len = selectedChild!.profits.length
        let lastProfit: DbProfit = {
          id: 0,
          from: len
            ? formatDate(
                moment(selectedChild!.profits[len - 1].to)
                  .add(1, 'day')
                  .toDate(),
              )
            : formatDate(moment(selectedChild!.startDate).add(1, 'day').toDate()),
          to: formatDate(thisTime().toDate()),
          amount: 0,
          status: ProfitStatus.Pending,
        } as any
        if (selectedChild!.status != InvestStatus.Settled) lastProfit = null as any
        else {
          lastProfit.amount = dayProfit(
            selectedChild!.amount,
            selectedChild!.ytm,
            moment().diff(lastProfit.from, 'days'),
          )
        }

        return [...selectedChild!.profits, lastProfit]
          .filter((v) => v != null)
          .map((v, index) => [
            <div className="flex gap-1" key={index}>
              <span>{index + 1}</span>
              {!v.id ? (
                <></>
              ) : isAdmin ? (
                <Tooltip content="Go to Distribution">
                  <Link
                    to={`/manageProfits?query=_${v.id}&status=${v.status}`}
                    className="font-bold cursor-pointer text-indigo-500 hover:underline"
                  >
                    #{v.id}
                  </Link>
                </Tooltip>
              ) : (
                <span className="font-bold cursor-pointer text-indigo-500 hover:underline">#{v.id}</span>
              )}
            </div>,
            formatDateLong(v.from),
            formatDateLong(v.to),
            `$ ${normalizeNumber(v.amount, 2)}`,
            <p className={`capitalize ${ProfitColorMap[v.status]} inline-block px-2 py-1 rounded text-sm capitalize`}>
              {v.status}
            </p>,
          ])
      }
    }
  }, [selectedChild, selectedChildText])

  const onLink = () => {
    if (action) return

    setAction('link')

    getEnvelopeUrl(selectedChild!.loan.id, selectedChild!.envelopeId)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setAction(''))
  }

  const onSellBack = async () => {
    if (action) return

    const result = await confirm(
      <p className="mb-4">
        There is a{' '}
        <b>
          {100 * PenaltyPercent}% ($ {normalizeNumber(selectedChild.amount * PenaltyPercent)})
        </b>{' '}
        transaction fee to exit the investment prior to payoff. Please confirm you wish to proceed.
      </p>,
    )
    if (!result) return

    setAction('sellback')
    signSellBackInvestment(selectedChild.id)
      .then(({ redirectUrl }) => {
        window.open(redirectUrl, '_self')
      })
      .catch(() => setAction(''))
  }

  const renderEvent = (event: InvestEvent) => (
    <div className="flex gap-2 mb-2" key={event.event_id}>
      <div className="flex flex-col">
        <div className="p-0.5 border rounded-full">
          <div className={`w-3 h-3 ${TransactionColorMap[event.event_type]} rounded-full`} />
        </div>
        <div className="border-l ml-2 flex-1" />
      </div>
      <div className="text-desc w-full">
        <div className="flex items-center gap-2 mb-2 justify-between">
          <p className="text-sm text-gray-800 capitalize">
            <TransactionStatus status={event.event_type} />
          </p>
        </div>
        <p className="mb-2">{event.description}</p>
        <p>{formatTimeLong(event.timestamp)}</p>
      </div>
    </div>
  )

  const onChangeInvest = (value: string) => {
    if (_invest !== null) {
      setSelectedChildText(value)
      console.log(value)

      const childId = value.split('#')[1]

      const childInvest = _invest.children.filter((child: DBInvestment) => {
        return child.id.toString() === childId
      })[0]
      setSelectedChild(childInvest)
    }
  }

  const { loan } = selectedChild

  return (
    <Modal
      isOpen
      title={`Investment #${selectedChild!.id}`}
      titleOkay=""
      titleCancel="Close"
      onClose={() => onClose(false)}
    >
      <div className="lg:w-196 w-[90vw] -m-6 overflow-y-auto">
        <LayoutLoading show={action == 'sellback'} />
        {_invest !== null && (
          <div className="mx-2 my-2">
            <Select2
              id="childSelect"
              title="Select Investment"
              options={_invest.mergedIds
                .replace(/^Investments\s*/, '') // Remove "Investments " from the beginning
                .split(',') // Split by commas
                .map((item) => item.trim()) // Remove extra spaces
                .filter((item) => item !== '')}
              // value={filters.accountType}
              className="capitalize"
              value={selectedChildText}
              onChange={(value) => onChangeInvest(value)}
            />
          </div>
        )}
        <div className="md:flex">
          <div className="flex-[2]">
            <Image src={toS3Link(loan.images)} className="w-full aspect-video object-cover" />
            <div className={`text-gray-600 text-md p-6`}>
              <p className="text-base text-gray-700 font-bold truncate mb-2 uppercase">
                {loan.propertyAddress} #{loan.id}
              </p>
              {Object.keys(renderData).map((title) => (
                <div className="py-1.5 flex gap-2 justify-between items-center border-b" key={title}>
                  <p className="text-desc">{title}</p>
                  <div className="text-base capitalize">{renderData[title]}</div>
                </div>
              ))}

              {selectedChild.status == InvestStatus.Pending && (
                <p className="text-desc mb-2">Open document and sign to complete investment.</p>
              )}
              {selectedChild.status == InvestStatus.Settled && isMine && (
                <div className="mt-4">
                  <Button outline color="yellow" onClick={onSellBack} size="sm">
                    <p className="flex gap-2 items-center">Retrade to Finresi.</p>
                  </Button>
                  <p className="text-desc">You need to pay {100 * PenaltyPercent}% transaction fee if you retrade.</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex-1 bg-slate-100 p-6">
            <p className="mb-2">Documents</p>

            <div className="border-b mb-6 text-sm">
              <>
                <a className="text-link flex items-center gap-2 mb-2 cursor-pointer" onClick={() => onLink()}>
                  {selectedChild!.status === InvestStatus.Pending ? 'Investment Sign' : 'Open Signed Document'}
                  {action == 'link' ? <Loading /> : <LinkIcon className="w-4 h-4" />}
                </a>
                {!!selectedChild!.penalty && !!Number(selectedChild!.penalty) && (
                  <a
                    className="text-link flex items-center gap-2 mb-2 cursor-pointer"
                    onClick={() => onLinkRetradedDoc}
                  >
                    Retrade to Finresi Sign
                    {action == 'retradedLink' ? <Loading /> : <LinkIcon className="w-4 h-4" />}
                  </a>
                )}
              </>
            </div>

            <p className="mb-2">Events</p>
            <div className="w-full">{selectedChild!.events.map(renderEvent)}</div>
          </div>
        </div>
        <div className="w-full px-6 py-2 text-sm">
          <p className="text-gray-700 font-bold mb-2">Distributions</p>
          {profits ? (
            <PlainTable
              tdClass="text-sm px-6 py-2 whitespace-nowrap"
              header={['No', 'From', 'To', 'Amount', 'Status']}
              data={profits}
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Modal>
  )
}
