import { ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { baseUrl, type InputType, INVALID_ALL_INPUTS } from 'config'
import { Tooltip } from 'flowbite-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import { Modal } from 'stories/components'
import { InputValidate, RenderInput, serialize } from 'utils'

const defaultInputs = (): Record<string, InputType> => {
  const currentYear = new Date().getFullYear()
  const lastYear = (new Date().getFullYear() - 1).toString()
  const yearsOption = []

  for (let year = 2023; year <= currentYear; year++) {
    yearsOption.push(year.toString())
  }

  return {
    yearVal: {
      inputType: 'select',
      title: 'Year',
      value: lastYear,
      error: '',
      options: yearsOption,
      required: true,
      span: 1,
    },
  }
}

export const ExportYearReport = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now())
  const [inputs, setInputs] = useState<Record<string, InputType>>(defaultInputs())

  const auth = useSelector((state: RootState) => state.auth)

  const onOpen = () => {
    setInputs(defaultInputs())
  }

  const onClose = () => {}

  const onChangeInput = async (key: string, value: any) => {
    const error = InputValidate({ ...inputs[key], value })
    let temp = cloneDeep(inputs)
    temp[key].value = value
    temp[key].error = error
    setInputs(temp)
  }

  const onExportExcel = () => {
    let data: any = {}
    Object.keys(inputs).map((key) => {
      data[key] = inputs[key].value
    })

    const url = `${baseUrl}/payment/report/year?${serialize(data)}&token=${auth.token}`

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = 'Invest_report.xml' // You can set a filename here if desired
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const onSubmit = () => {
    let hasError = false
    let data: any = {}
    let temp = cloneDeep(inputs)

    Object.keys(inputs).map((key) => {
      data[key] = inputs[key].value
      const error = InputValidate(inputs[key])
      if (error.length > 0) {
        temp[key].error = error
        hasError = true
      }
    })

    setInputs(temp)
    if (hasError) {
      return toast(INVALID_ALL_INPUTS, { type: 'error' })
    }

    setIsOpen(false)
    setLastUpdatedAt(Date.now())
    onExportExcel()
  }

  return (
    <Modal
      button={
        <Tooltip content="Export Yearly Report">
          <div className="p-1 shadow hover-shadow1 cursor-pointer rounded text-indigo-500 transition-all duration-200">
            <ArrowDownCircleIcon className="w-5 h-5" />
          </div>
        </Tooltip>
      }
      title={'Yearly Report'}
      titleOkay="Export"
      isOpen={isOpen}
      lastUpdatedAt={lastUpdatedAt}
      onClose={onClose}
      onOk={onSubmit}
      onOpen={onOpen}
    >
      <div className="w-[480px] h-fit">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {Object.keys(inputs).map((key, index) => {
            let input = inputs[key]
            if (input.visible === false) return null
            return (
              <div key={index} className={`md:col-span-${input.span}`}>
                <RenderInput input={input} Key={key} onChange={onChangeInput} />
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
