import { CurrencyDollarIcon, LinkIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Loading } from 'components/Loading'
import { TransactionColorMap } from 'components/TransactionStatus'
import { AccountType, IRADetails, isTopManager, itemCountPerPage } from 'config'
import { Tooltip } from 'flowbite-react'
import { upperFirst } from 'lodash'
import { IRAType } from 'pages/Auth/SignUp/Section'
import { type DBInvestment, FundsType, FundsTypeColorMap, IFundsType, InvestStatus } from 'pages/Payment'
import { InvestDetails } from 'pages/Payment/modal'
import type { User } from 'pages/User'
import { UserDetails } from 'pages/User/UserDetails'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import type { RootState } from 'reducers'
import {
  cancelInvestment,
  getEnvelopeUrl,
  getInvestments,
  getUser,
  returnFundsForPaidOff,
  updateDateTransfer,
} from 'services/apis'
import { Input2, MultiSelect, Pagination, Toggle } from 'stories/components'
import {
  confirm,
  formatDateLong,
  getPrice3decimal,
  getSimplifiedAddress,
  isEmpty,
  normalizeNumber,
  renderHeader,
  useTitle,
} from 'utils'

import { CompleteCustodianInvest } from './modal'
import { ExportInvestReport } from './modal/ExportInvestReport'
import { ExportYearReport } from './modal/ExportYearReport'
import { EnvelopeColorMap } from './SignStatistics'

export const ManageSignStatisticsPage = () => {
  useTitle('Manage Investments')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('query') || ''
  const defaultQuery = query.replace(/_/g, '#')
  const defaultPaidOff = Boolean(searchParams.get('showOnlyPaidOff'))
  const navigate = useNavigate()

  const status: string[] = (searchParams.get('status') || 'settled').split(',')
  const defaultStatus: Record<string, boolean> = {}
  Object.keys(InvestStatus).forEach((key) => {
    defaultStatus[key] = true
  })
  if (status.length && status[0] != '') {
    Object.keys(InvestStatus).forEach((key) => {
      if (!status.includes(key.toLowerCase())) defaultStatus[key] = false
    })
  } else {
    Object.keys(InvestStatus).forEach((key) => {
      defaultStatus[key] = false
    })
  }

  const defaultEnevelopeStatus: Record<string, boolean> = {}
  Object.keys(EnvelopeColorMap)
    .map((v) => upperFirst(v))
    .forEach((key) => {
      if (key.toLowerCase() === 'voided') defaultEnevelopeStatus[key] = false
      else defaultEnevelopeStatus[key] = true
    })

  const [filters, setFilters] = useState<Record<string, any>>({
    query: defaultQuery,
    envelopeStatus: defaultEnevelopeStatus,
    status: defaultStatus,
    paidOff: defaultPaidOff,
    orderBy: 'id',
    orderDir: '-1',
    pageNum: 0,
  })
  const [filterQuery, setFilterQuery] = useState(filters.query)
  const [total, setTotal] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<DBInvestment[]>([])
  const [isGetUsersOnce, setIsGetUsersOnce] = useState(false)
  const [loadingId, setLoadingId] = useState('')
  const [selectedItem, setSelectedItem] = useState<DBInvestment | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const auth = useSelector((state: RootState) => state.auth)
  const { accountType } = auth.profile
  const _isManager = isTopManager(accountType)

  useEffect(() => {
    window.scrollTo(window.scrollX, 0)
    filterData(filters).then(() => {
      setIsGetUsersOnce(true)
    })
  }, [])

  useEffect(() => {
    if (filters.query == defaultQuery) return

    onChangeFilter('query', defaultQuery)
  }, [defaultQuery])

  useEffect(() => {
    if (filterQuery.trim() === '') {
      const currentURL = window.location.pathname
      navigate(currentURL, { replace: true })
    }
    if (!isGetUsersOnce) return
    const timeOutId = setTimeout(() => !isLoading && onChangeFilter('pageNum', 0), 700)
    return () => clearTimeout(timeOutId)
  }, [filterQuery])

  const filterData = (filters: Record<string, any>, _pageNum: number = -1) => {
    if (filters?.query) filters.query = filters.query.trim()
    if (_pageNum === -1) _pageNum = filters.pageNum
    setLoading(true)
    const status: string[] = []
    Object.keys(InvestStatus).forEach((key) => {
      if (filters.status[key] !== false) status.push(key.toLowerCase())
    })
    const envelopeStatus: string[] = []
    Object.keys(EnvelopeColorMap)
      .map((v) => upperFirst(v))
      .forEach((key) => {
        if (filters.envelopeStatus[key]) envelopeStatus.push(key.toLowerCase())
      })

    return getInvestments({
      ...filters,
      envelopeStatus: envelopeStatus,
      status,
      isAll: true,
      skip: _pageNum * itemCountPerPage,
      count: itemCountPerPage,
    })
      .then(({ data, total }) => {
        setTotal(total)
        setData(data)
      })
      .finally(() => setLoading(false))
  }

  const onPageNavigate = (num: number) => {
    onChangeFilter('pageNum', num)
  }

  const onChangeFilter = (
    key: 'query' | 'envelopeStatus' | 'status' | 'orderBy' | 'orderDir' | 'pageNum' | 'paidOff',
    value: any,
    refetch = true,
  ) => {
    if (isLoading) return
    const newFilters = Object.assign({}, filters)
    newFilters[key] = value
    setFilters(newFilters)
    if (key === 'query') setFilterQuery(value)
    else if (refetch) filterData(newFilters)
  }

  const onOpenUser = (userId: number) => {
    getUser(userId).then((data) => {
      data && setSelectedUser(data)
    })
  }

  const onLink = (id: number, envelopeId: string) => {
    if (loadingId) return

    setLoadingId(envelopeId)
    getEnvelopeUrl(id, envelopeId)
      .then((res) => {
        if (res?.url) window.open(res.url, '_blank')
      })
      .finally(() => setLoadingId(''))
  }

  const onCancel = async (item: DBInvestment) => {
    let content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to Cancel this Investment?
        <br />
        <span className="text-gray-600">Investment: #{item.id}</span>
      </div>
    )

    let result = await confirm(content)
    if (!result) return

    result = false

    if (item.status == InvestStatus.Settled) {
      content = (
        <div className="text-gray-700 mb-4 text-[18px]">
          Do you want to distribute profits after <b>cancel</b> this?
        </div>
      )

      result = await confirm(content)
      if (result === undefined) return
    }

    await cancelInvestment(item.id, result)
    toast(`Investment #${item.id} has been cancelled.`, { type: 'success' })
    filterData(filters)
  }

  const fillTransferFund = async (item: DBInvestment, value: boolean) => {
    const orderMsg = value ? 'set' : 'remove'
    let content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to {orderMsg} date transferred to lender?
        <br />
        <span className="text-gray-600">Investment: #{item.id}</span>
      </div>
    )

    let result = await confirm(content)
    if (!result) return

    await updateDateTransfer(item.id)

    if (value) toast(`Date Transferred to Lender has been set.`, { type: 'success' })
    else toast(`Date Transferred to Lender has been removed.`, { type: 'success' })

    filterData(filters)
  }

  const returnFunds = async (item: DBInvestment) => {
    let content = (
      <div className="text-gray-400 mb-4 text-[18px]">
        Do you want to approve returning the funds of this investment?
        <br />
        <span className="text-gray-600">Investment: #{item.id}</span>
      </div>
    )

    let result = await confirm(content)
    if (!result) return

    result = false

    content = (
      <div className="text-gray-700 mb-4 text-[18px]">
        Do you want to distribute profits after <b>returning</b> this funds?
      </div>
    )

    result = await confirm(content)
    if (result === undefined) return

    await returnFundsForPaidOff(item.id, result)

    filterData(filters)
  }

  const _renderHeader = (title: string, sortable: boolean = false, key: string) => {
    return renderHeader({
      title,
      index: 0,
      key,
      sortable,
      onSort: (key: string, sortOrder: number) => {
        const newFilters = Object.assign({}, filters)
        newFilters['orderBy'] = key
        newFilters['orderDir'] = sortOrder
        setFilters(newFilters)
        filterData(newFilters)
      },
      sortOrder: filters.orderBy == key ? filters.orderDir : 0,
      className: 'whitespace-nowrap',
    })
  }

  return (
    <div className="fullContent pt-16 min-h-[100vh]">
      <div className="md:flex justify-between items-center mb-0 sm:mb-10">
        <p className="text-4xl font-semibold flex gap-4 items-center mb-10 sm:mb-0">
          Manage Investments ({total}) {isLoading && <Loading />}
        </p>
        <div className="flex justify-end gap-2">
          <ExportInvestReport />
          <ExportYearReport />
        </div>
      </div>

      <div className="grid items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <Input2
          type="search"
          title="Search"
          hasIcon
          icon={<MagnifyingGlassIcon className="w-4 h-4" />}
          value={filters.query}
          onChange={(value) => onChangeFilter('query', value)}
        />
        <MultiSelect
          id="envelopeStatus"
          title="Envelope Status"
          options={Object.keys(EnvelopeColorMap).map((v) => upperFirst(v))}
          value={filters.envelopeStatus}
          className="capitalize"
          hasDefaultOption
          defaultOptionText=""
          onChange={(value) => onChangeFilter('envelopeStatus', value, false)}
          onBlur={() => onChangeFilter('envelopeStatus', filters.envelopeStatus)}
        />
        <MultiSelect
          id="status"
          title="Investment Status"
          options={Object.keys(InvestStatus)}
          value={filters.status}
          className="capitalize"
          hasDefaultOption
          defaultOptionText=""
          onChange={(value) => onChangeFilter('status', value, false)}
          onBlur={() => onChangeFilter('status', filters.status)}
        />
      </div>

      <div className="table-container mt-4 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full whitespace-nowrap text-sm text-left text-gray-900 dark:text-gray-400 pl-6">
          <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {_renderHeader('No', false, 'no')}
              {_renderHeader('ID', true, 'id')}
              <th scope="col" className="py-2 px-2">
                <span>
                  <div className="border-b w-fit mb-1 border-gray-300">Name</div>
                  Invest As
                </span>
              </th>
              {_renderHeader('Loan', true, 'loanId')}
              {_renderHeader('Amount', true, 'amount')}
              {_renderHeader('Funds Type', true, 'fundsType')}
              <th scope="col" className="py-2 px-2">
                <span>
                  <div className="border-b w-fit mb-1 border-gray-300">Distribution</div>
                  Penalty
                </span>
              </th>
              <th scope="col" className="py-2 px-2">
                <span>
                  <div className="border-b w-fit mb-1 border-gray-300">Envelope</div>
                  Status
                </span>
              </th>
              <th scope="col" className="py-2 px-2">
                <span>
                  <div className="border-b w-fit mb-1 border-gray-300">Created At</div>
                  Transferred To Lender
                </span>
              </th>
              {_renderHeader('Actions', false, 'action')}
            </tr>
          </thead>
          <tbody className="text-[14px]">
            {data.map((item, index) => {
              return (
                <tr key={item.id} className={`border-b ${index % 2 && 'bg-slate-50'} text-gray-900`}>
                  <td className="pl-3 py-3">{index + filters.pageNum * itemCountPerPage + 1}</td>
                  <td className="px-2 py-2">
                    <div
                      className="font-bold text-indigo-500 hover:underline cursor-pointer"
                      onClick={() => setSelectedItem(item)}
                    >
                      #{item.id}
                    </div>
                  </td>
                  <td className="px-2 py-2">
                    <Tooltip content="Show User Details">
                      <div
                        className="border-b w-fit mb-1 border-gray-300 font-bold text-indigo-500 hover:underline cursor-pointer"
                        onClick={() => item.user.id && onOpenUser(item.user.id)}
                      >
                        {item.user.name}
                      </div>
                    </Tooltip>
                    <div className="font-bold text-indigo-500 hover:underline cursor-pointer">{item.investAs}</div>
                  </td>
                  <td className="px-2 py-2">
                    <div className="flex gap-2 items-center">
                      <Link to={`/marketplace/${item.loan.id}`} className="hover:text-indigo-500 hover:underline">
                        <span>{getSimplifiedAddress(item.loan.propertyAddress)}</span>
                      </Link>
                      <Link to={`/manageSignStatistics?query=__${item.loan.id}`}>
                        <span className="font-bold text-indigo-500 hover:underline cursor-pointer">
                          #{item.loan.id}
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">${normalizeNumber(item.amount)}</td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <FundsTypeBox data={item.fundsType} />
                  </td>
                  <td className="px-2 py-2">
                    <p
                      className={`text-green-500 whitespace-nowrap ${
                        !!item.retradeEnvelopeId && 'border-b w-fit mb-1 border-gray-300'
                      }`}
                    >
                      ${normalizeNumber(item.profit || 0, 2)}
                    </p>

                    <div className="flex items-center gap-2">
                      {!!item.penalty && (
                        <p className="text-red-500 whitespace-nowrap">-${normalizeNumber(item.penalty)}</p>
                      )}

                      {!item.retradeEnvelopeId ? null : loadingId == item.retradeEnvelopeId ? (
                        <Loading />
                      ) : (
                        <Tooltip content="Open Signed Retraded Document">
                          <div
                            onClick={() => onLink(item.loan.id, item.retradeEnvelopeId!)}
                            className="font-bold text-indigo-500 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <LinkIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                  <td className="px-2 py-2">
                    <div className="flex gap-2 items-center capitalize border-b w-fit mb-1 border-gray-300">
                      <span className={`w-2 h-2 rounded-full ${EnvelopeColorMap[item.envelopeStatus || 'sent']}`} />
                      {item.envelopeStatus || 'sent'}
                    </div>
                    <div className="flex gap-2 items-center capitalize">
                      <span className={`w-2 h-2 rounded-full ${TransactionColorMap[item.status]}`} />
                      {item.status}
                    </div>
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap">
                    <div className={`${!!item.dateFundTransfer && 'border-b w-fit mb-1 border-gray-300'}`}>
                      {formatDateLong(item.createdAt)}
                    </div>
                    <div>{item.dateFundTransfer !== null ? formatDateLong(item.dateFundTransfer) : ''}</div>
                  </td>
                  <td className="px-2 py-2">
                    <div className="flex gap-2">
                      {!item.envelopeId ? null : loadingId == item.envelopeId ? (
                        <Loading />
                      ) : (
                        <Tooltip
                          content={`Open ${item.status === InvestStatus.Pending ? '' : 'Signed'} Investment Document`}
                        >
                          <div
                            onClick={() => onLink(item.loan.id, item.envelopeId)}
                            className="font-bold text-indigo-500 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <LinkIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}

                      {_isManager && (
                        <Tooltip content="Funds Transfered to Lender">
                          <Toggle
                            id={'dateTransferFund' + index}
                            value={!isEmpty(item.dateFundTransfer)}
                            onChange={(value) => fillTransferFund(item, value)}
                          />
                        </Tooltip>
                      )}

                      {_isManager &&
                        [InvestStatus.Pending].includes(item.status) &&
                        item.user.accountType === AccountType.Ira &&
                        (item.user.accountDetails as IRADetails)?.IRA_Type === IRAType.Custodian && (
                          <CompleteCustodianInvest userId={item.user.id} loanId={item.loan.id} investId={item.id} />
                        )}

                      {_isManager && [InvestStatus.Pending, InvestStatus.Settled].includes(item.status) && (
                        <Tooltip content="Cancel Investment">
                          <div
                            onClick={() => onCancel(item)}
                            className="font-bold text-red-500 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <XMarkIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}

                      {_isManager && item.isPaidOff && (
                        <Tooltip content="Approve Return">
                          <div
                            onClick={() => returnFunds(item)}
                            className="font-bold text-green-400 transition-all duration-200 hover:underline cursor-pointer hover-shadow1 p-1"
                          >
                            <CurrencyDollarIcon className="w-4 h-4" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center flex-wrap gap-2 mt-3 mb-3">
        <Toggle
          id="paidOff"
          title="Show Only Paid Off"
          value={filters.paidOff}
          onChange={() => {
            if (isLoading) return
            const newFilters = Object.assign({}, filters)

            const targetStatus = filters.paidOff ? 'settled' : 'returned'
            const defaultStatus: Record<string, boolean> = {}
            Object.keys(InvestStatus).forEach((key) => {
              if (key.toLowerCase() === targetStatus) defaultStatus[key] = true
              else defaultStatus[key] = false
            })
            newFilters['status'] = defaultStatus
            newFilters['paidOff'] = !filters.paidOff
            setFilters(newFilters)

            filterData(newFilters)
          }}
          className="!w-48"
        />

        <div>
          <Pagination
            totalCount={total}
            itemCountPerPage={itemCountPerPage}
            onNavigate={onPageNavigate}
            pageNum={filters.pageNum}
          />
        </div>
      </div>
      {selectedItem && (
        <InvestDetails data={selectedItem as DBInvestment} isMine={false} onClose={() => setSelectedItem(null)} />
      )}

      {selectedUser && <UserDetails readOnly data={selectedUser} onClose={() => setSelectedUser(null)} />}
    </div>
  )
}

const FundsTypeBox = ({ data }: { data: IFundsType | undefined }) => {
  if (!data) return <></>

  if (data.type === FundsType.Both) {
    const tooltip = (
      <div>
        <p>New Funds: ${getPrice3decimal(data.newFunds)}</p>
        <p>Returned Funds: ${getPrice3decimal(data.returnedFunds)}</p>
      </div>
    )
    return (
      <Tooltip content={tooltip}>
        <div
          className={`${
            FundsTypeColorMap[data.type]
          } p-1 rounded-md capitalize hover:cursor-pointer text-center text-xs w-28`}
        >
          {data.type}
        </div>
      </Tooltip>
    )
  } else
    return (
      <div
        className={`${
          FundsTypeColorMap[data.type]
        } p-1 rounded-md capitalize hover:cursor-pointer text-center text-xs w-28`}
      >
        {data.type}
      </div>
    )
}
